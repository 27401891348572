import styles from './index.module.css';

function CountryButton({countryCode, selectCountry}) {
    function handleClick() {
        selectCountry({countryCode});
    }
    return <div class={styles.clickableCountry + ' text-center  px-1'} onClick={handleClick}>
        {countryCode}
    </div>
}

export default CountryButton;