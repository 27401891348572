
export const fetchGeojson = async () => {
    return await fetch('/api/static/geojson')
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => console.error(error));
};

export const fetchSummary = async () => {
    return await fetch('/api/dictionary/latest')
    .then(response => response.json())
    .then(data => {
        return {...data, availableCountries: Object.keys(data.dictionaries)};
    })
    .catch(error => {});
}

export const enrichData = (geojsonData, summary) => {
    var filteredData = {...geojsonData};
    filteredData.features = [];
    for(let i=0; i<geojsonData.features.length; i++) {
        filteredData.features.push({...geojsonData.features[i], customProperties: summary.dictionaries[geojsonData.features[i].properties.ISO_A2_EH]});
    }
    return filteredData;
}

export const fetchLatest = async () => {
    return await fetch('/api/row/latest')
    .then(response => response.json())
    .then(data => {
      return data;
    })
    .catch(error => {});
}

export const fetchCountryDetails = async (id) => {
    return await fetch('/api/row/' + id)
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {});
}

export const fetchLocationHistory = async (location, nextToken) => {
    let body = {
            location: location,
            nextToken: nextToken
        };
    return await fetch('/api/row/location', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(locationData => {
        return locationData;
    })
    .catch(error => {});
}

export const create = async (location, description) => {
    let body = {
        location: location,
        description: description
    };
    return await fetch('/api/row', {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    })
    .then(response => {
        return response.ok;
    })
    .catch(error => {});
}