import { useState, forwardRef, useImperativeHandle } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { fetchCountryDetails } from '../../util/api';

import styles from './index.module.css';

const Country = forwardRef(({selectCountry}, ref) => {
    const [countryData, setCountryData] = useState(null);
  
    const updateCountryData = async ({id, location}) => {
      if(!id && location) {
        setCountryData({location: location});
        return;
      } else if(!id) {
        setCountryData(null);
        return;
      }
      
      let countryData = await fetchCountryDetails(id);
      if(!countryData) {
        setCountryData();
        return;
      }
      let aggregates = countryData.aggregates && JSON.parse(countryData.aggregates);
      setCountryData({...countryData, aggregates: {sentiment: aggregates.sentiment, keyPhrases: aggregates.keyPhrases}, location: location});
      return countryData;
    }

    useImperativeHandle(ref, () => ({
      updateCountryData
    }));
    
    function getKeyPhrases() {
      if(!countryData?.aggregates) {
        return <></>;
      }
      let keyPhrases = countryData.aggregates.keyPhrases;
      return keyPhrases && Object.keys(keyPhrases).map( kp => {
        return <Col className="p-0 px-1 m-0 mt-1"><span class={styles.keyPhrase}>{kp}:{keyPhrases[kp]}</span></Col>
      })
    }

    function getHighlights() {
      if(!countryData) {
        return <></>;
      }else if(!countryData.aggregates) {
        return <>
        <Col>
          <Row><div>LOCATION</div></Row>
          <Row><div class="text-center" >{countryData.location}</div></Row>
        </Col>
        </>
      }
      let sentiment = countryData.aggregates.sentiment;
      return <>
        <Col>
          <Row><div>LOCATION</div></Row>
          <Row><div class="text-center" >{countryData.location}</div></Row>
        </Col>
        <Col>
          <Row><div>POSITIVE</div></Row>
          <Row><div class="text-center" >{sentiment.POSITIVE}</div></Row>
        </Col>
        <Col>
          <Row><div>NEUTRAL</div></Row>
          <Row><div class="text-center" >{sentiment.NEUTRAL}</div></Row>
        </Col>
        <Col>
          <Row><div>MIXED</div></Row>
          <Row><div class="text-center" >{sentiment.MIXED}</div></Row>
        </Col>
        <Col>
          <Row><div>NEGATIVE</div></Row>
          <Row><div class="text-center" >{sentiment.NEGATIVE}</div></Row>
        </Col>
        <Col>
          <Row><div>Last 24 hours</div></Row>
        </Col>
      </>
    }
    
    return (
      <>
      <div class={styles.detailPanel + ' p-0 m-0'}>
        <Container fluid className={'p-0 m-0 ' + styles.stickyHeader }>
          <Row xs="auto" className="p-0 pb-1 m-0 sticky-top">
            {getHighlights()}
          </Row>
        </Container>
        <Container fluid className="p-0 m-0" style={{ flex: 1, overflowY: "auto" }}>
          <Row xs="auto" className="p-0 m-0">
            {getKeyPhrases()}
          </Row>
        </Container>
        </div>
      </>
    )

});

export default Country;