import { useState, useEffect, forwardRef, useImperativeHandle} from 'react';
import { Tabs, Tab} from 'react-bootstrap';
import FeedItem from '../FeedItem';
import { fetchLatest, fetchLocationHistory } from '../../util/api';

import styles from './index.module.css';

const Feed = forwardRef(({selectCountry}, ref) => {
    const [latest, setLatest] = useState(null);
    const [countryHistory, setCountryHistory] = useState(null);
    const [selectedTabKey, setSelectedTabKey] = useState('liveFeed');

    useEffect(() => {
      async function ul() {
        setLatest(await fetchLatest());
      }
      ul();
    }, []);

    function updateLatest(newLatest) {
      setLatest(newLatest);
    }

    async function updateCountryHistory(location) {
      setCountryHistory(await fetchLocationHistory(location));
    }

    async function loadMore() {
      let moreCountryHistory = await fetchLocationHistory(countryHistory.items[0].location, countryHistory.nextToken);
      setCountryHistory({
        items: [...countryHistory.items, ...moreCountryHistory.items],
        nextToken: moreCountryHistory.nextToken
      })
    }

    useImperativeHandle(ref, () => ({
      updateLatest,
      updateCountryHistory
    }));
    
    function getLoadMoreButton() {
      return <><br/><a href="#" onClick={loadMore} class="text-center">Load more...</a></>;
    }
    function getLatestElements() {
      if(latest && latest.length) {
        let latestElements = latest.map( d => <FeedItem data={d} selectCountry={selectCountry} showTitle="true"/>);
        return <div className="sidepanel">{latestElements}</div>;
      } else {
        return <div className="sidepanel"></div>;
      }
    }
        
    function getCountryHistoryElements() {
      if(countryHistory?.items?.length) {
        let countryHistoryElements = countryHistory?.items.map( d => <FeedItem data={d}/>);
        return <div className="sidepanel">
          {countryHistoryElements}
          {countryHistory.nextToken && getLoadMoreButton()}
        </div>;
      } else {
        return <div className="sidepanel"></div>;
      }
    }

    const navItems = [
      {
        key: 'liveFeed',
        label: 'Live Feed',
        content: getLatestElements()
      },
      {
        key: 'countryHistory',
        label: 'Country History',
        content: getCountryHistoryElements()
      } 
    ];
    
    function getTabs() {
      return navItems.map(item => <Tab eventKey={item.key} title={item.label}>{item.content}</Tab>)
    }

  return <>
    <Tabs
        id="tabs"
        activeKey={selectedTabKey}
        onSelect={(k) => setSelectedTabKey(k)}
        className=""
      >
       {getTabs()}
      </Tabs>
  </>
});

export default Feed;