import { createContext, useContext, useReducer, useEffect } from 'react';
import { fetchGeojson, fetchSummary, enrichData } from '../util/api';

const DataContext = createContext(null);

const DataDispatchContext = createContext(null);

export function DataProvider({ children }) {
  const [data, dispatch] = useReducer(
    dataReducer,
    null
  );

  useEffect(() => {
    async function fetchData() {
      const geoJsonData = await fetchGeojson();
      const summary = await fetchSummary();
      dispatch({ type: 'set', payload: { geoJsonData: geoJsonData, summary, filteredData: enrichData(geoJsonData, summary) } });
    }

    fetchData();
  }, []);

  return (
    <DataContext.Provider value={data}>
      <DataDispatchContext.Provider value={dispatch}>
        {children}
      </DataDispatchContext.Provider>
    </DataContext.Provider>
  );
}

export function useData() {
  return useContext(DataContext);
}

export function useDataDispatch() {
  return useContext(DataDispatchContext);
}

function dataReducer(state, action) {
  switch (action.type) {
    case 'set': {
      return action.payload;
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}