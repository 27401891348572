import Card from 'react-bootstrap/Card';
import CountryButton from '../CountryButton';

import styles from './index.module.css';

function FeedItem({data, selectCountry, showTitle}) {

    function parseISOStringToLocalDate(epoch) {
        if(!epoch) {
            return 'Unknown Date';
        }
        const date = new Date(epoch);
      
        const options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: false
        };
      
        return date.toLocaleString(undefined, options);
      }

    return <>
        <Card className={styles.card}>
            <Card.Body className={styles.body}>
                {showTitle && <Card.Title className={styles.title}><CountryButton countryCode={data.location} selectCountry={selectCountry}/></Card.Title>}
                <Card.Text className={styles.text}>
                    {data.description}
                    <br/>
                    <i>{parseISOStringToLocalDate(data.createdEpoch)}</i>
                </Card.Text>
            </Card.Body>
        </Card>
    </>
}

export default FeedItem;