import { useEffect } from 'react';
import { useData, useDataDispatch } from '../context/dataContext';
import { enrichData } from './api';

const ws = new WebSocket('wss://' + window.location.host + '/ws');

ws.addEventListener('open', (event) => {});

ws.addEventListener('close', (event) => {});

function WsController({latestHook, botHook}) {
    const data = useData();
    const dispatchData = useDataDispatch();

    useEffect(() => {
        const runControls = (eventData) => {
            switch (eventData.type) {
                case 'dictionaries':
                    let newData = {...eventData.details, availableCountries: Object.keys(eventData.details.dictionaries)};
                    dispatchData({ type: 'set', payload: { geoJsonData: data.geoJsonData, summary: newData, filteredData: enrichData(data.geoJsonData, newData) } });  
                    break;
                case 'latest':
                    latestHook(eventData.details);  
                    break;
                case 'bot':
                    botHook(eventData.details);  
                    break;
                default:
                    break;
            }
        }

        let messageEventListener = (event) => {
            let eventData = JSON.parse(event.data);
            runControls(eventData);
        };

        ws.addEventListener('message', messageEventListener);
    
        return () => {
            ws.removeEventListener('message', messageEventListener);
        };
    }, [latestHook, data?.geoJsonData, dispatchData]);
    

    return <></>

}

export default WsController;