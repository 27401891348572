
import { useState, createRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Map from './components/Map';
import Country from './components/Country';
import Feed from './components/Feed';
import { DataProvider } from './context/dataContext';
import WsController from './util/wsController';

import './assets/bootstrap.css';
import './assets/App.css';

function App() {
  const [mapRef] = useState(createRef());
  const [countryRef] = useState(createRef());
  const [feedRef] = useState(createRef());
  const [botRef] = useState(createRef());

  const fetchCountryDetails = async ({id, location}) => {
    await feedRef.current.updateCountryHistory(location);
    return await countryRef.current.updateCountryData({id, location});
  }
  
  const selectCountry = ({countryCode}) => {
    mapRef.current.selectCountry({countryCode});
  }

  function latestHook(newLatest) {
    feedRef.current.updateLatest(newLatest);
  }

  function botHook(newBot) {
    mapRef.current.updateBot(newBot);
    botRef.current.updateBot(newBot);
  }

  return (
    <DataProvider>
      <WsController latestHook={latestHook} botHook={botHook}/>
      <div className="App" title="Main Board">
        <Container fluid className="p-0 m-0 containedBox">
          <Row className="p-0 m-0">
            <Col xs={9} className="p-0 m-0 mapView" title="Interactive Map">
              <Map ref={mapRef} fetchCountryDetails={fetchCountryDetails}></Map>
            </Col>
            <Col xs={3} className="p-0 m-0 sideView" title="Live Feed and Country History">
              <Feed ref={feedRef} selectCountry={selectCountry}/>
            </Col>
          </Row>
          <Row className="p-0 m-0 detailsView" title="Keyword Summary and Stats">
              <Country ref={countryRef} selectCountry={selectCountry}></Country>
          </Row>
        </Container>
      </div>
    </DataProvider>
  );
}

export default App;
