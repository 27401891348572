
import { useEffect, useRef, useCallback, forwardRef, useImperativeHandle } from 'react';
import L from 'leaflet';
import 'leaflet-polylinedecorator';
import 'leaflet/dist/leaflet.css';
import rotatedRedPin from './rotatedRedPin.png';

import countryCoordinates from '../../data/countryCoordinates.json';
import { useData } from '../../context/dataContext';

const selectedCountryIcon = L.icon({
    iconUrl: rotatedRedPin,
    iconSize: [32, 32],
    iconAnchor: [15, 30]
 });

const osmUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
const mapOptions = {
    maxZoom: 10,
    minZoom: 1,
    maxBounds: [
        [-180, -360], // Southwest coordinates
        [180, 360],   // Northeast coordinates
      ]
}

const Map = forwardRef(({fetchCountryDetails}, ref) => {
    const data = useData();
    const mapDivRef = useRef(null);
    var mapInstance = useRef(null);
    
    const selectCountry = ({countryCode}) => {
        if(!data.summary.dictionaries[countryCode]) {
            mapInstance.current.clickFeature({target: {feature: {properties: {ISO_A2_EH: countryCode} }}});
            return;
        }
        mapInstance.current.clickFeature({target: {feature: {properties: {ISO_A2_EH: countryCode}, customProperties: {id: data.summary.dictionaries[countryCode].id} }}});
    }

    const updateBot = (bot) => {
        mapInstance.current.drawBot(bot.path);
    }
    useImperativeHandle(ref, () => ({
        selectCountry,
        updateBot
      }));

    const getColor = useCallback(
      (sentiment) => {
        if(!sentiment) {
            return 'silver';
        }
        let max = Object.keys(sentiment).reduce((acc, s) => sentiment[s] > acc[1]? [s, sentiment[s]]: acc, ['NEUTRAL', 0]);
        if(max[0] === 'POSITIVE') {
            return 'Green';
        } else if(max[0] === 'MIXED') {
            return 'Yellow';
        } else if(max[0] === 'NEGATIVE') {
            return 'red';
        } else {
            return 'CornflowerBlue';
        }
      },
      [],
    )

    const style = useCallback(
        (feature) => {
          return {
              weight: 2,
              opacity: 1,
              color: 'DodgerBlue',
              dashArray: '3',
              fillOpacity: 0.7,
              fillColor: getColor(feature.customProperties?.sentiment)
          };
        },
        [getColor],
      )

    useEffect(() => {
        if(data && mapDivRef.current) {
            let newMapInstance = {
                map: L.map(mapDivRef.current, mapOptions).setView([0, 0], 2),
                locked: true
            };

            const addTile = () => {
                L.tileLayer(osmUrl, {
                    attribution: 'Map data &copy; OpenStreetMap contributors',
                    maxZoom: 10,
                    }).addTo(newMapInstance.map);
            }

            const render = () => {
                newMapInstance.geojson = L.geoJson(data.filteredData, {
                    style,
                    onEachFeature: onEachFeature
                }).addTo(newMapInstance.map);
            }
            
            const addInfo = () => {
                var info = L.control();
            
                info.onAdd = function () {
                    this._div = L.DomUtil.create('div', 'info');
                    this.update();
                    return this._div;
                };
                
                info.update = function (info) {
                    if(info?.sentiment) {
                        this._div.innerHTML = `<h6>Summary</h6>`+
                            `&uArr; ${info.sentiment.POSITIVE}`+
                            `<br/>&neArr; ${info.sentiment.NEUTRAL}`+
                            `<br/>&seArr; ${info.sentiment.MIXED}`+
                            `<br/>&dArr; ${info.sentiment.NEGATIVE}`;
                    } else {
                        this._div.innerHTML = `<h6>Summary</h6>`;
                    }
                };
                
                info.addTo(newMapInstance.map);
                newMapInstance.info = info;
            }

            const getInfo = (countryCode) => {
                if(countryCode){
                    return data.summary.dictionaries[countryCode];
                } else {
                    return null;
                }
            }
            
            const highlightFeature = (e) => {
                const layer = e.target;
                layer.setStyle({
                    weight: 4,
                    color: 'Navy',
                    dashArray: '',
                    fillOpacity: 0.7
                });
            
                layer.bringToFront();
                
                newMapInstance.info.update(getInfo(e.target.feature.properties.ISO_A2_EH));
            }

            const resetHighlight = (e) => {
                newMapInstance.info.update();
                newMapInstance.geojson.resetStyle(e.target);
            }

            const clickFeature = async (e) => {
                if(newMapInstance.locked) {
                    return;
                }
                newMapInstance.lock();
                let countryCode = e.target.feature.properties.ISO_A2_EH;
                newMapInstance.selectedCountryMarker?.remove(newMapInstance.map);
                if(newMapInstance.selectedCountry === countryCode) {
                    await fetchCountryDetails({});
                    newMapInstance.selectedCountry = null;
                    newMapInstance.unlock();
                    return;
                }
                await fetchCountryDetails({id: e.target.feature.customProperties?.id, location: countryCode});

                newMapInstance.unlock();
                newMapInstance.selectedCountry = countryCode;
                newMapInstance.selectedCountryMarker = new L.Marker( [countryCoordinates[countryCode].latitude, countryCoordinates[countryCode].longitude], {icon: selectedCountryIcon}).addTo(newMapInstance.map);
            }
            newMapInstance.clickFeature = clickFeature;
            
            newMapInstance.lock = () => { newMapInstance.locked = true; };
            newMapInstance.unlock = () => { newMapInstance.locked = false; };

            const onEachFeature = (feature, layer) => {
                layer.on({
                    mouseover: highlightFeature,
                    mouseout: resetHighlight,
                    click: clickFeature
                });
            }


            const drawBot = (path) => {
                newMapInstance.botPolyline?.remove(newMapInstance.map);
                newMapInstance.botPolylineDecorator?.remove(newMapInstance.map);
                if(!path || !path.length) {
                    return;
                }
                var pathCoordinates = path.map(p => [countryCoordinates[p].latitude, countryCoordinates[p].longitude]);
                newMapInstance.botPolyline = L.polyline(pathCoordinates, {color: 'blue', weight: '2', opacity: '0.5'}).addTo(newMapInstance.map);
                newMapInstance.botPolylineDecorator = L.polylineDecorator(newMapInstance.botPolyline, {
                    patterns: [
                        {offset: 0, repeat: 100, symbol: L.Symbol.arrowHead({pixelSize: 10, polygon: false, pathOptions: {stroke: true, opacity: '0.75' }})}
                    ]
                }).addTo(newMapInstance.map);
            }
            newMapInstance.drawBot = drawBot;

            addTile();
            render();
            addInfo();
            if(mapInstance.current?.selectedCountry) {
                let updateCountryData = async (selectedCountry) => {
                    await fetchCountryDetails({id: data.summary.dictionaries[selectedCountry]?.id, location: selectedCountry});
                    newMapInstance.selectedCountry = selectedCountry;
                }
                updateCountryData(mapInstance.current.selectedCountry);
            }

            newMapInstance.unlock();
            mapInstance.current = newMapInstance;
            
            return () => {
                newMapInstance.map.remove();
            };
        }


    }, [data, mapDivRef, style, fetchCountryDetails]);

    return <div ref={mapDivRef} style={{ height: '100%'}}/>
});


export default Map;

